/**
 * Message constants
 */
export const SESSION_EXPIRED = 'Your session has expired';
export const ERROR_MESSAGE_500 = 'Internal Server Error';
export const ERROR_SOMETHING_WRONG = 'Oops! Something went wrong';
export const PASSWORD_NOT_MATCHED = 'Text entered in Password & Confirm Password should be same';
export const LOADING = 'Loading...';
export const SIGNUP_EMAIL_SENT = 'Please verify your email address to get started';
export const FORGET_EMAIL_SENT = 'Reset link has been sent on your email address';
export const PASSWORD_RESET_SUCCESS = 'Password has been reset successfully';
export const IMAGE_SIZE_EXCEED = 'Image file size should be less than 2 MB';
export const VIDEO_SIZE_EXCEED = 'Image file size should be less than 100 MB';
export const INVALID_FORMAT = 'Please upload JPG/PNG image';
export const MAX_MEDIA_NUM = {
    IMAGE: 'You can only upload 5 images at a time',
    VIDEO: 'You can only upload 5 videos at a time'
};

export const INVALID_VIDEO_FORMAT = 'Please upload MP4 files.';
export const SAVED_SUCCESS = 'Saved successfully';
export const MEASUREMENT_UPDATE_SUCCESS = 'Measurements have been updated!';
export const EMPTY_ADDRESS = 'Please provide an address to continue';
export const EXPORTING = 'Exporting...';
export const PAYMENT_SUCCESS = 'Payment received successfully. Invoice is being generated.';
export const PAYMENT_ERROR = 'Unable to receive payment. Please try again';
export const QUERY_SEND_SUCCESS = 'We have received your request for custom plan. We will reach out to you shortly';
export const BILLING_NAME_ERROR = 'Please provide billing name';
export const BILLING_EMAIL_ERROR = 'Please provide billing email';
export const BILLING_ADDRESS_ERROR = 'Please provide billing address';
export const BILLING_ZIP_ERROR = 'Please provide a valid billing ZIP';
export const BILLING_CITY_ERROR = 'Please provide billing city';
export const BILLING_COUNTRY_ERROR = 'Please provide billing country';
export const BILLING_ERROR = 'Please provide valid billing details';
export const FILL_BILLING_DETAILS = 'Please complete billing details to allow card save';
export const REQUEST_FAILED = 'Request failed. Please try again';
export const REQUEST_CANCELLED_SUCCESS = 'Your request is cancelled';
export const EMAIL_SENT_SUCCESS = 'Email sent successfully';
export const UNSUBSCRIBE_SUCCESS = 'You have unsbuscribed your plan';
export const DOWNLOADING_INVOICE = 'Downloading...';
export const EXPORT_ERROR = 'Unable to export. Please try again';
export const CARD_UPDATE_SUCCESS = 'Card updated successfully';
export const CARD_UPDATE_ERROR = 'Unable to update card. Please try again';
export const CARD_REMOVE_SUCCESS = 'Card removed successfully';
export const CARD_REMOVE_ERROR = 'Unable to remove card. Please try again';
export const USAGE_SUMMARY_ERROR =
    'Usage Summary is not available for this company. Please contact support to get it added.';
export const REPORT_TYPE_ERROR =
    'This takeoff type is not added with this user. Please contact support to get it added.';

export const PARCEL_NOT_PRESENT = 'No Lot Boundary found. Please add the Lot Boundary to be measured';
export const PARCEL_OUTSIDE_IMAGE = 'Lot Boundary cannot be extended beyond HD imagery';
export const MULTIPLE_PARCEL_ERROR = 'You can only have one Lot Boundary at a time';
export const FEATURE_ADD_GEOM_MISMATCH = 'Can not add %s into %s';
export const BULK_EXPORT_SUCCESS =
    'Your exports are being generated. You will receive an email with the link to download them soon.';
export const ASYNC_PDF_EXPORT_SUCCESS = 'Your PDF report is being generated and will be downloaded soon.';
export const ASYNC_JPG_EXPORT_SUCCESS = 'Your JPEG report is being generated and will be downloaded soon.';
export const PDF_EXPORT_ON_MAIL =
    'Your Pdf report is being generated. You will receive an email with the link to download the report soon.';
export const JPG_EXPORT_ON_MAIL =
    'Your JPEG export is being generated. You will receive an email with the link to download the export soon.';
export const EXCEL_EXPORT_ON_MAIL =
    'Your Excel export is being generated. You will receive an email with the link to download the export soon.';
export const DOWNLOAD_SUPPLEMENTARY_FILES = 'Supplementary files downloaded successfully';
export const EXPORT_SCREENSHOT = 'Screenshot downloaded successfully';
('Your Excel export is being generated. You will receive an email with the link to download the export soon.');
export const EXPORT_ALL_SUCCESS = 'Your export is being generated, you will receive the email shortly.';
export const EXPORT_DOWNLOAD_SUCCESS = 'Your exports are downloaded successfully';
export const QUEUE_REMOVE_SUCCESS =
    'The requests have been removed from the queue and saved as "Draft" requests in "MY REQUESTS".';
export const SHARE_LINK_ERROR = 'Unable to generate link. Please try again.';
export const LINK_COPY_SUCCESS = 'Link Copied';
export const LINK_RESET_SUCCESS = 'A new shareable link has been generated and copied. Now you can share this link.';
export const LINK_COPY_ERROR = 'Unable to copy link';
export const CHANGE_SUCCESS = 'Changes saved successfully.';
export const TAX_EXCLUSIVE_MSG = 'Prices shown are exclusive of any applicable sales taxes such as VAT.';

/**
 * Send Invite
 */
export const INVITE_SEND_SUCCESS = 'Invite sent to "%s"';
export const EMAIL_EMPTY_ERROR = 'Please enter email ID';
export const PLAN_EMPTY_ERROR = 'Please select plan';
export const REMOVE_FROM_TEAM_SUCCESS = '%s has been removed from your team';
export const INVITE_REMOVE_SUCCESS = 'Invite sent to "%s" has been cancelled';

export const REPORT_CREATED_AND_SELECTED = '"%s" has been created and selected for measurements';
export const REPORT_SELECTED = '"%s" has been selected for measurements';

export const QUEUE_ORDER_CHANGED_SUCCESS = 'Order of requests in the queue has been changed';
export const QUEUE_ORDER_CHANGED_ERROR = 'There was an error in changing the order of the queue. Please try again!';

export const DEFAULT_INVITE_MESSAGE =
    "Hey %s!  I wanted to share an amazing new technology in the industry with you.\n\nI've been using Attentive's automated takeoff software to measure properties, and it's been an absolute game-changer! It's saved me so much time and effort. The best part is, it uses AI technology to measure properties accurately, so no more manual measurements and less chance of messing up. Seriously, the results have been spot on every time!\n\nIf you're looking for a hassle-free way to measure properties, I highly recommend giving Attentive a try. They currently offer a free trial, which is a great opportunity to experience the benefits firsthand. \n\nHave a great day!";
/**
 * Measurement Update
 */
export const PARCEL_UPDATE_SUCCESS = 'New Lot boundary has been saved';
export const PARCEL_DELETE_SUCCESS = 'Lot boundary has been deleted. Please add a new boundary';
export const FEATURE_ADD_SUCCESS = 'Features have been saved and measurements have been updated';
export const HOLES_SUCCESS = 'Holes have been saved and measurements have been updated';
export const CANNOT_ADD_NEARBY = 'You cannot add nearby properties after editing the default lot boundary';
export const NEARBY_SAVE_SUCCESS = 'Nearby parcel(s) have been added and takeoff details updated accordingly';
export const GROUP_ADD_SUCCESS = 'New property-feature added. Reclassify or add %s to the group';
export const GROUP_DELETE_SUCCESS = 'Feature group has been deleted';
export const GROUP_RENAME_SUCCESS = 'Feature group has been renamed to "%s"';

export const CSV_EXPORT_ON_MAIL =
    'Your CSV report is being generated. You will receive an email with the link to download the report soon';
export const RATE_REQUIRED = 'Please rate before submitting';

export const TOOLTIP_EXPORT_RECENT_DOWNLOAD = 'Download the last generated export';

// Upload Icons
export const ICONS_SAVED = 'Icons added successfully';
export const ICONS_DELETED = 'Icons deleted successfully';
export const ICONS_CHANGED = 'Icons changed successfully';
export const ICONS_SAVE_FAILED = 'Unable to save icons';

// Arrows
export const ARROWS_SAVED = 'Arrows added successfully';
export const ARROWS_SAVE_FAILED = 'Unable to save arrows';

export const IMAGERY_SELECT_FAILED = 'Unable to select this imagery, try another imagery';

// Blueprint Takeoff
export const PROPERTY_NAME_REQUIRED = 'Property name is required.';
export const REQUIREMENTS_REQUIRED = 'Scope of the takeoff is required.';
export const HAS_LEGEND_REQUIRED = 'Select whether the blueprint has legend or not.';
export const BLUEPRINT_FILE_REQUIRED = 'Blueprint files are required.';
export const BLUEPRINT_SCOPE_REQUIRED = 'Blueprint scope is required.';
export const BLUEPRINT_FILES_SIZE_EXCEEDED = 'Combined size of all files should not exceed 250 Mb.';
export const BLUEPRINT_CORRECTION_FILES_SIZE_EXCEEDED = 'Combined size of all files should not exceed 2 Mb.';
export const BLUEPRINT_FILES_NUMBER_EXCEEDED =
    'You can upload up to 10 blueprint files only. To upload more files, please contact support.';
export const MAX_FILE_SIZE_EXCEEDED = 'Maximum file size is %s Mb';
export const NOT_ALLOWED_FILE_FORMAT = `Only pdf files are allowed`;
export const BPT_TAKEOFF_SCOPE = 'Scope';
export const BPT_MANUAL_TAKEOFF_SCOPE = 'Site notes';
export const BLUEPRINT_REQUEST_RECEIVED =
    'Plans uploaded successfully, processing will begin soon. You will be redirected to the takeoff shortly.';
export const BLUEPRINT_EXPORT_PDF_SUCCESS =
    'Your PDF export is being generated. It might take upto 10 minutes for you to receive an email with the link to download the export.';
export const BLUEPRINT_EXPORT_EXCEL_SUCCESS =
    'Your Excel export is being generated. It might take upto 10 minutes for you to receive an email with the link to download the export.';
export const BLUEPRINT_DRAFT_DELETED = 'Draft has been deleted';
export const OLD_BLUEPRINT_CLICK_MESSAGE =
    'This request can not be clicked because it was delivered before edit functionality was launched.';

// Top Banner
export const UPDATE_CARD_BANNER =
    'Your card information has not been updated on the platform yet. To avoid any interruption in services, kindly add your card before %s/%s/%s'; // DD/MM/YYYY

// Shared Access
export const TAKEOFF_ACCESS_REVOKED = '%s has been removed from this takeoff.';
export const TAKEOFF_ACCESS_SHARED_SELF = '%s has been shared with you successfully.';
export const TAKEOFF_ACCESS_SHARED_BULK = 'Takeoffs shared with the selected users successfully!';
export const TAKEOFF_ACCESS_SHARED = 'Takeoff has been shared with %s successfully.';

// Custom Blueprint Request
export const EDIT_DESCRIPTION_SUCCESS = 'Feature description has been updated';
export const EDIT_FEATURE = 'Feature Edited Successfully';
export const FEATURE_CREATION_SUCCESS = 'Feature created successfully';
export const FEATURE_DELETION_SUCCESS = 'Feature deleted successfully';
export const CUSTOM_FEATURE_LENGTH_ERROR = 'Feature name and description should be less than 200 characters';
export const CUSTOM_FEATURE_MEASURE_TYPE_ERROR = 'Feature measure type is required';
export const CUSTOM_FEATURE_MATERIALS_REQUIRED = 'You must add atleast one material to save assembly feature';
export const FEATURE_CREATION_FAILURE = 'Error in feature creation';
export const FEATURE_UPDATE_FAILURE = 'Error in feature update';

// Material Messages
export const MATERIAL_UPDATED = 'Material Updated Successfully';
export const MATERIAL_ADDED = 'Material Added Successfully';
export const MATERIAL_UPDATE_FAILURE = 'Error in feature update. Please refresh the page';

// Concurrency
export const ASSIGN_CONCURRENCY_OVERFLOW = 'You cannot assign more than the purchased concurrencies';
export const ASSIGN_CONCURRENCY_SUCCESS =
    "Your concurrency assignment will be completed as soon as the number of in progress requests get below the company's max concurrency limit";
export const PURCHASE_CONCURRENCY_OVERFLOW = 'You cannot purchase more than %s %s';

export const RESUBMIT_REQUEST_DISCLAIMER =
    'Please note that the lot boundary has been extended outside HD imagery. We would recommend resubmitting this request instead of manual annotation as the exports might not be accurate in this case';
export const REQUEST_DISCLAIMER =
    'Please note that the lot boundary has been extended outside HD imagery. We would recommend submitting a new takeoff for the additional area as the exports might not be accurate in this case';
// Manual Measurements
export const SAVE_DEFAULT_MANUAL_MEASUREMENT_SUCCESS =
    'Your feature list has been saved as default for Manual measurement takeoffs';

// Property View Content
export const PARCEL_EDIT_MESSAGE =
    'Attentive will not make any modifications or adjustments to the lot boundaries or parcels that you have edited or drawn.';

// Switch Desktop Message
export const SWITCH_DESKTOP_MESSAGE = 'Switch to desktop for a better viewing experience';
export const SWITCH_DESKTOP_BPT_MESSAGE = 'Switch to desktop for a better viewing experience';

// Zonal Measurement
export const ZONE_DELETE_SUCCESS = 'Zone has been deleted';
export const ZONE_RENAME_SUCCESS = 'Zone has been renamed to "%s"';
export const ZONE_ADD_SUCCESS = 'Features have been saved and measurements have been updated';
export const ZONE_MERGE_SUCCESS = 'Zones are merged successfully.';

// scale
export const SCALE_CHANGE_MESSAGE =
    'The scale has been corrected, as there was a mismatch between the plan scale and some available dimensions on the plan';

// Tooltips for HOA
export const COMMON_AREA_TOOLTIP =
    'Choose this option to measure all non-fenced in areas only. Includes common lawns, recreational center(s), etc and excludes private fenced in yards.';
export const EXCLUDE_CURB_TOOLTIP =
    'Also known as mow strips or road-verge. Select this if you don’t want Attentive to measure curb strips in the HOA.';
export const FRONT_YARDS_TOOLTIP =
    'Choose this option to include softscape features and private driveway in front of private property in the measurement based on the takeoff type.';

// Discount card on draft stage

export const COMMON_AREA_DISCOUNT =
    'A flat 30% discount is applied on this takeoff. Please note only the non-fenced in common areas will be measured.';
export const FRONT_YARD_DISCOUNT =
    'A flat 30% discount is applied on this takeoff. Please note only the non-fenced in common areas and the features of front yard along with front private driveways will be measured.';

export const CREATE_TAGS = 'Press Enter after each %s name. After all the names have been entered, click Save.';
export const TAGS_TOGGLE = 'Click the eye icon to show/hide additional %ss.';

// Folder
export const FOLDER_CREATED = 'Folder created successfully !';
export const FOLDERS_MOVED = 'Selected %s moved successfully!';
